// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log("Vite ⚡️ Rails")

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log(
//   "Visit the guide for more information: ",
//   "https://vite-ruby.netlify.app/guide/rails"
// )

import * as Turbo from "@hotwired/turbo";
Turbo.start();

// import ActiveStorage from "@rails/activestorage"
// ActiveStorage.start()

import "../../assets/stylesheets/application.scss";

import { Application } from "@hotwired/stimulus";
import MainController from "../controllers/autocomplete_controller";

const application = Application.start();
application.register("autocomplete", AutocompleteController);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

// import flatpickr from "flatpickr";
// window.flatpickr = flatpickr;

// import { registerControllers } from "../lib/stimulus-vite-helpers";
// registerControllers(
//   application,
//   import.meta.globEager("../../views/**/*_controller.ts")
// );

export { application };
